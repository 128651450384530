import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import TagManager from 'react-gtm-module';
import Script from 'next/script';

import Header from '../components/Header';
import Footer from '../components/Footer';
import Modal from '../components/Modal';
import PlayerPopup from '../components/PlayerPopup';
import BackToTop from '../components/BackToTop';
import CookiesBanner from '../components/CookiesBanner';

import { playerModalGetOpen } from '../redux/modal/playerModal.selector';
import { initGTMconsent } from '../utils/gtm';
import { getCookieConsent } from '../redux/global/global.selector';
import { loadShareThisScript } from '../utils/sharethis';
import { setupLinkedInManual } from '../utils/linkedIn';
import { useRouter } from 'next/router';

const Layout = props => {
    const state = useSelector(state => state);

    const isPlayerModalOpen = playerModalGetOpen(state);

    const {
        header,
        footer,
        ga_id: gaId,
        // search,
        cookies,
        footerGroup,
        firstCompType,
    } = props;

    const noSplash = firstCompType !== 'error' && firstCompType !== 'heroBanner' && firstCompType !== 'landingPageHeroBanner';

    const cookieAccept = getCookieConsent(state);
    useEffect(() => {
        initGTMconsent();
        if (cookieAccept) {
            const tagManagerArgs = {
                gtmId: gaId,
            };
            TagManager.initialize(tagManagerArgs);
            loadShareThisScript();
            setupLinkedInManual();
        }
    }, []);

    useEffect(() => {
        if (cookieAccept) {
            const tagManagerArgs = {
                gtmId: gaId,
            };
            TagManager.initialize(tagManagerArgs);
            loadShareThisScript();
            setupLinkedInManual();
        }
    }, [cookieAccept]);

    const router = useRouter();
    // Add a temporary, manual, brute-force LinkedIn tracker.  To be replaced by gtm asap.
    // This appears in layout/index.js, and withModal.js
    useEffect(() => {
        if (!window['liinsights'] && window['liinsights_load']) {
            router.events.on('routeChangeComplete', (url) => {
                window['lintrk']();
            });
            window['liinsights'] = true;
        }
    }, []);

    return (
        <>
            <Script id='google-analytics' strategy='afterInteractive'>
                {`
                    window.dataLayer = window.dataLayer || [];
                    function gtag(){window.dataLayer.push(arguments);}
                `}
            </Script>
            <Header
                {...header}
                socialMedia={footerGroup.socialMedia}
                noSplash={noSplash}
            />
            <main className={['main-content', noSplash ? 'no-splash' : 'splash'].join(' ')} id='main-content'>
                {props.children}
            </main>
            <BackToTop />
            <Footer {...footer} {...footerGroup} />
            <Modal isOpen={isPlayerModalOpen} modalClass='player'>
                <PlayerPopup isModal />
            </Modal>
            <CookiesBanner {...cookies} />
        </>
    );
};

Layout.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    header: PropTypes.object,
    footer: PropTypes.object,
    footerGroup: PropTypes.object,
    ga_id: PropTypes.string,
    cookies: PropTypes.object,
    firstCompType: PropTypes.string,
    search: PropTypes.object,
    // footerGroup: PropTypes.object,
};

Layout.defaultProps = {
    search: {
        link: '/search',
    },
};

export default Layout;
