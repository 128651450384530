import React from 'react';
import PropTypes from 'prop-types';
import Head from 'next/head';
import { NextSeo } from 'next-seo';
import Layout from '../layout';
import { render } from '../components/render';
import { getPage } from '../redux/page/page.action';
import { pageGetData } from '../redux/page/page.selector';
import { getGlobal } from '../redux/global/global.action';
import { globalGetData, globalInit } from '../redux/global/global.selector';
// import Offices from '../components/Offices';

const IndexPage = ({ Global, Seo, Contents }) => {
    const firstCompType = Contents ? Contents[0].type : '';

    return (
        <Layout {...Global} firstCompType={firstCompType}>
            <NextSeo title={Seo.pageTitle} description={Seo.pageDescription} canonical={Seo.pageUrl} />
            <Head>
                <link rel="shortcut icon" href={Global.favicon?.url} />
                <meta name="title" content={Seo.pageTitle} />
                <meta name="keywords" content={Seo.pageKeywords} />
                <meta property="og:url" content={Seo.pageUrl} />
                <meta property="og:title" content={Seo.pageOgTitle} />
                <meta property="og:description" content={Seo.pageOgDescription} />
                <meta property="og:image" content={Seo.pageOgImage} />
            </Head>
            {/* <Offices {...Contents[14]} />
            <Banner {...Contents[0]} />
            <HomeTreasers {...Contents[1]} />
            <LatestNews {...Contents[2]} />
            <LifeAtPermira {...Contents[3]} /> */}
            {Contents && Contents.map((content, i) => render(content, i))}
        </Layout>
    );
};

IndexPage.getInitialProps = async (ctx) => {
    const { query } = ctx;
    const { lang, preview, pageId } = query;

    const { dispatch, getState } = ctx.store;

    await dispatch(getPage('home', lang, preview, pageId));
    const PageData = pageGetData(getState());

    // global header, footer
    const isGlobalInit = globalInit(getState());

    if (!isGlobalInit) {
        await dispatch(getGlobal(lang));
    }

    const GlobalData = globalGetData(getState());
    const Global = GlobalData.data;

    // const Global = reformatGlobal(PageData.Global, Labels, lang);
    const Seo = PageData.SEO;
    const Contents = PageData.contents;

    return {
        Global,
        Seo,
        Contents,
    };
};

IndexPage.propTypes = {
    Global: PropTypes.object,
    Seo: PropTypes.object,
    Contents: PropTypes.array,
};

export default IndexPage;
